import {
  OPEN_ADMIN_CANCEl_BOOKING_MODAL,
  CLOSE_ADMIN_CANCEl_BOOKING_MODAL
} from '../constants';

export default function modalReducer(state = {}, action) {
  switch (action.type) {
    case OPEN_ADMIN_CANCEl_BOOKING_MODAL:
      return {
        ...state,
        isAdminCancelModalOpen: action.payload.isOpen,
      };

    case CLOSE_ADMIN_CANCEl_BOOKING_MODAL:
      return {
        ...state,
        isAdminCancelModalOpen: action.payload.isOpen,
      };

    default:
      return {
        ...state,
      };
  }
}
